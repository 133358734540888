import {
  forceCollide,
  forceSimulation,
  forceManyBody,
  forceX,
  forceY,
} from "d3-force";
import { geoMercator, geoPath } from "d3-geo";
import { max } from "d3-array";
import { scaleLinear } from "d3-scale";
import gsap from "gsap";

// import forceLayoutSettings from "./forceLayoutSettings";
import PlayableTimeline from "./PlayableTimeline";
import {
  colorByCluster,
  isMobile,
  smallestScreenDimension,
  transactionIsMortgaged,
  TRANSACTION_FINANCIALIZATION,
} from "./utils";

const DEBUG_CANVAS_CLICK = false;

const UNASSIGNED = "Estado Colombiano";

// These indicate how a sphere should be rendered
const TYPE_LAND = `Land`;
const TYPE_MORTGAGE = `Mortgage`;
// const TYPE_BOTH = `Both`;

export const RENDER_VIEW = {
  CLUSTERS: 1,
  MAP: 2,
};

export class LandClusters extends PlayableTimeline {
  _tickNum = 0;
  // 0 draws nodes at full opacity
  // 1 draws map at full opacity
  _mapOpacity = { value: 0 };
  _focused = null;
  _debugInteraction = {
    x: null,
    y: null,
  };

  clusterLayout = undefined;
  layout = [];
  width = window.innerWidth;
  height = window.innerHeight;

  constructor({
    addForceManyBody,
    el,
    clusters,
    clustersOrder,
    edges,
    nodes,
    cases = [],
    land,
    plotShapes,
    playOnStart = false,
    squareGrid = true,
    timeline,
    onTimelineChange,
    onLandClusterChange,
    onNodeClick,
  }) {
    super({
      ...timeline,
      onChange: ({ data, date, animateTransactions }) => {
        this.renderTransactions(data, animateTransactions),
          onTimelineChange({ date });
      },
    });

    this.plotShapes = plotShapes;
    // console.log(this.plotShapes);
    this.projectionSettings = {
      width: this.height / 1.5,
      height: this.height / 1.5,
    };
    this.mapProjection = geoMercator().fitSize(
      [this.projectionSettings.width, this.projectionSettings.height],
      this.plotShapes
    );

    this.addForceManyBody = addForceManyBody;
    this.squareGrid = squareGrid;

    this.onNodeClickCb = onNodeClick;
    this.onLandClusterChange = onLandClusterChange;

    this.renderView = RENDER_VIEW.CLUSTERS;

    this.render = this.render.bind(this);

    this.scales = {
      r: scaleLinear()
        .domain([0, max(land, (d) => d["Surface"])])
        .range([1, smallestScreenDimension() / 30]),
    };

    this.initCanvas(el);

    this.nodes = nodes;
    this.land = land;
    this.clusters = clusters;
    this.clustersOrder = clustersOrder;

    // Define plot centers
    this.plotCenterX = this.width / 2;
    this.plotCenterY = this.height / 2.25;

    this.initLayout();

    // Kicks off inherited Timeline class
    // this.play();

    if (playOnStart) {
      this.play();
    } else {
      this.pause();
    }

    // renders data
    window.requestAnimationFrame(this.render);
  }

  onNodeClick(n) {
    this._focused = n?.name;
    this.onNodeClickCb(n);
  }

  initLayout() {
    // This keeps track of which force layout a node is in
    this.nodeForceLayoutIdx = new Map();

    // Keeps track of land accumulation
    this.landAccumulation = new Map();

    this.clusterCoords = new Map();

    // keep track of which lands are on which cluster
    this.landClusterMap = new Map();

    this.land.forEach((l) =>
      this.landClusterMap.set(l["Predio_Nom"], UNASSIGNED)
    );

    let clusterIdx = 0;

    const orderedClusters = this.clustersOrder.filter((c) =>
      [...this.clusters.keys()].includes(c)
    );

    orderedClusters
      // [...this.clusters.keys()]
      .forEach((clusterName, idx) => {
        if (clusterName === UNASSIGNED) {
          return;
        }
        // this.clusters-size - 1 because Estado Colombiano is in center
        let rx =
          this.plotCenterX +
          (this.height / 3.2) *
            Math.cos((clusterIdx / (this.clusters.size - 1)) * 2 * Math.PI);
        // this.clusters-size - 1 because Estado Colombiano is in center
        let ry =
          this.plotCenterY +
          (this.height / 3.2) *
            Math.sin((clusterIdx / (this.clusters.size - 1)) * 2 * Math.PI);

        // if (this.squareGrid) {
        //   const PER_ROW = 4;
        //   const i = Math.floor(clusterIdx / PER_ROW);
        //   const j = clusterIdx % PER_ROW;

        //   rx = 0.1 * this.width + (j / PER_ROW) * 0.8 * this.width;
        //   ry = 0.2 * this.height + (i * 0.8 * this.height) / PER_ROW;
        // }

        this.clusterCoords.set(clusterName, {
          name: clusterName,
          x: rx,
          y: ry,

          color: colorByCluster(clusterName),
        });

        clusterIdx++;
      });

    this.clusterCoords.set(UNASSIGNED, {
      name: UNASSIGNED,
      x: this.plotCenterX,
      y: this.plotCenterY,
      color: colorByCluster(UNASSIGNED),
    });

    // secondary layout for financialized assets
    this.mortgagesLayout = forceSimulation([])
      .alphaTarget(0.3)
      .velocityDecay(0.3)
      .force("x", forceX((d) => d.tx).strength(0.06))

      .force("y", forceY((d) => d.ty).strength(0.06))
      .force("collide", forceCollide((d) => d.r * 1.1).iterations(4))
      .stop();

    this.clusterLayout = forceSimulation(
      this.land
        .map((l, idx) => {
          const name = l["Predio_Nom"];
          // console.log(name);

          // the features of the individual plot
          const features =
            this.plotShapes.features.find(
              (p) => p.properties["Nomb_Matr"] === name
            ) || undefined;

          // don't includes lands we can't draw
          // if (!features) return undefined;

          let r = this.scales.r(l["Surface"]);
          // let r = Math.ceil(
          //   Math.sqrt(geoPath(this.mapProjection).area(features))
          // );

          // !features && console.warn(`couldn't find features for ${name}`);

          // the projection of the plot
          const projection = geoMercator().fitExtent(
            [
              [-r, -r],
              [r, r],
            ],
            features
          );

          const shape = new Path2D(geoPath(projection)(features));

          const geospatial = new Path2D(geoPath(this.mapProjection)(features));

          // console.log(
          //   `centroid`,
          //   geoPath(this.mapProjection).centroid(features),
          //   `bounds`,
          //   geoPath(this.mapProjection).bounds(features),
          //   `area`,
          //   geoPath(this.mapProjection).area(features),
          //   `measure`,
          //   geoPath(this.mapProjection).measure(features)
          // );
          // console.log("land", idx, `rgb(${idx}, ${idx}, ${idx})`);

          return {
            name: name,

            // initially send all nodes to center
            x: this.plotCenterX,
            y: this.plotCenterY,
            tx: this.plotCenterX,
            ty: this.plotCenterY,

            shape,
            geospatial,
            map: {
              centroid: geoPath(this.mapProjection).centroid(features),
            },
            mortgages: 0, // keep track of how many mortgages each plot has
            hitColor: `rgb(${idx + 10}, 0, 0)`, // color in the hit region
            color: colorByCluster(UNASSIGNED),
            r,
            // r: this.scales.r(l["Surface"]),
            type: TYPE_LAND,
            clusterHistory: [], // keep track of movements here
          };
        })
        .filter((d) => d !== undefined)
    )
      .alphaTarget(0.3)
      .velocityDecay(0.3)
      .force("x", forceX((d) => d.tx).strength(0.06))
      .force("y", forceY((d) => d.ty).strength(0.06))
      // .force("repel", forceManyBody().strength(-0.2))
      .force("collide", forceCollide((d) => d.r * 1.1).iterations(4))
      .force(
        "charge",
        forceManyBody()
          .strength((d, i) => -d.r * 1)
          .distanceMax(smallestScreenDimension() / 2)
          .distanceMin(1)
      )
      // .alphaDecay(0.1)
      // .force("repel", forceManyBody().strength(-1))
      // .alpha(1)
      .stop();

    if (this.addForceManyBody) {
      this.mortgagesLayout.force(
        "charge",
        forceManyBody()
          .strength((d, i) => -d.r * 2)
          .distanceMax(smallestScreenDimension() / 2)
          .distanceMin(1)
      );
    }

    this.onLandClusterChange(
      this.clusterLayout.nodes().map((n) => ({
        name: n.name,
        color: n.color,
      }))
    );
  }

  initCanvas(el) {
    // this.canvasEl = el.appendChild(document.createElement("canvas"));
    // let scale = window.devicePixelRatio; // Change to 1 on retina screens to see blurry canvas.
    // this.canvasEl.style.width = this.width + "px";
    // this.canvasEl.style.height = this.height + "px";
    // this.canvasEl.width = Math.floor(this.width * scale);
    // this.canvasEl.height = Math.floor(this.height * scale);

    // this.ctx = this.canvasEl.getContext("2d");
    // // Normalize coordinate system to use css pixels.
    // this.ctx.scale(scale, scale);
    const appendCanvasEl = (el) => {
      const canvasEl = el.appendChild(document.createElement("canvas"));
      let scale = window.devicePixelRatio; // Change to 1 on retina screens to see blurry canvas.
      canvasEl.style.width = this.width + "px";
      canvasEl.style.height = this.height + "px";
      canvasEl.width = Math.floor(this.width * scale);
      canvasEl.height = Math.floor(this.height * scale);

      const ctx = canvasEl.getContext("2d");
      // Normalize coordinate system to use css pixels.
      ctx.scale(scale, scale);

      return { canvasEl, ctx };
    };

    const { canvasEl, ctx } = appendCanvasEl(el);
    const { canvasEl: hitCanvasEl, ctx: hitCtx } = appendCanvasEl(el);
    this.hitCtx = hitCtx;
    this.canvasEl = canvasEl;
    hitCanvasEl.style =
      "position: absolute; top: 0; left: 0; pointer-events: none; display: none;";
    this.ctx = ctx;
    this.hitCtx.scale(1 / window.devicePixelRatio, 1 / window.devicePixelRatio);
    // console.log(this.ctx, this.hitCtx);

    // add click handler
    this.canvasEl.addEventListener("click", this.onCanvasClick.bind(this));
  }

  serialize() {
    return [...this.clusterLayout.nodes(), ...this.mortgagesLayout.nodes()].map(
      ({
        mortgages,
        type,
        hitColor,
        color,
        shape,
        geospatial,
        map,
        stroke,
        r,
        name,
        x,
        y,
      }) => {
        return {
          hitColor,
          color: type === TYPE_LAND ? color : `transparent`,
          strokeStyle:
            type === TYPE_MORTGAGE
              ? this.clusterCoords.get("Banco").color
              : mortgages === 0
              ? this.clusterCoords.get("Banco").color
              : `transparent`,
          name,
          shape,
          geospatial,
          map,
          x,
          y,
          r,
        };
      }
    );
  }

  changeRenderView(v) {
    console.log("changing render view", v);
    gsap.to(this._mapOpacity, {
      value: v === RENDER_VIEW.MAP ? 1 : 0,
      overwrite: true,
      duration: 5,
      ease: "power3.out",
      onComplete: () => (this.renderView = v),
    });
    // this.renderView = v;

    if (v === RENDER_VIEW.MAP) {
      this.clusterLayout.nodes().forEach((n) => {
        n.tx =
          n.map.centroid[0] +
          this.width / 2 -
          this.projectionSettings.width / 2;
        n.ty =
          n.map.centroid[1] +
          this.height / 2 -
          this.projectionSettings.height / 2;
      });
      this.clusterLayout
        // .alphaTarget(0)
        .force("collide", null)
        .force("charge", null);
    } else {
      this.clusterLayout.nodes().forEach((n) => {
        const { x, y } = this.clusterCoords.get(
          this.landClusterMap.get(n.name)
        );
        n.tx = x;
        n.ty = y;
      });

      this.clusterLayout
        // .alpha(1)
        // .alphaTarget(0.3)
        .force("collide", forceCollide((d) => d.r * 1.1).iterations(4))
        .force(
          "charge",
          forceManyBody()
            .strength((d, i) => -d.r * 2)
            .distanceMax(smallestScreenDimension() / 2)
            .distanceMin(1)
        );
    }
    console.log("changing render view", v);
  }

  render() {
    // if (this.renderView === RENDER_VIEW.CLUSTERS) {
    // SIMULATION
    this.tick();
    // }

    // DRAWING
    this.ctx.clearRect(0, 0, this.width, this.height);
    this.hitCtx.clearRect(0, 0, this.width, this.height);
    const nodesOpacity = 1 - this._mapOpacity.value;

    this.ctx.save();
    this.ctx.globalAlpha = this._mapOpacity.value;
    this.hitCtx.globalAlpha = this._mapOpacity.value;
    this.drawMap();
    this.drawMapLegend();
    this.ctx.restore();

    this.ctx.save();
    this.ctx.globalAlpha = nodesOpacity;
    nodesOpacity > 0 && this.drawNodes();
    this.drawLegend();
    this.ctx.restore();

    if (DEBUG_CANVAS_CLICK) {
      this.ctx.fillStyle = "red";
      this.ctx.beginPath();
      this.ctx.arc(
        this._debugInteraction.x,
        this._debugInteraction.y,
        10,
        0,
        2 * Math.PI
      );
      this.ctx.fill();
      this.ctx.stroke();
    }

    // ANIMATION
    window.requestAnimationFrame(this.render);
  }

  renderTransactions(transactions, animate = true) {
    // Animates transactions taking place,
    // animates land going in and out
    // What's the index of the force layout?
    if (transactions.length) {
      const changedNodes = [];
      for (let transaction of transactions) {
        // console.log(transaction)
        // const srcCluster = this.clusterCoords.get(transaction["Name_Source"]);
        const transactionType = transaction["Trans_Category"];

        const targetCluster = this.clusterCoords.get(
          transaction["Cluster_Target"]
        );

        const name = transaction["Predio_Nom"];

        // the node in question we're trying to animate
        const node = this.clusterLayout.nodes().find((n) => n.name === name);
        !node && console.log("node not found", name, this.land);
        if (transactionType === TRANSACTION_FINANCIALIZATION) {
          // look weather land is being mortgaged
          const isMortgaged = transactionIsMortgaged(transaction);

          if (isMortgaged) {
            // check number of mortgages already made
            if (++node.mortgages === 1) {
              // first mortgage made
              // add to other mortgage force layout
              const mortgagedNode = {
                name,
                x: node.x,
                y: node.y,
                tx: this.clusterCoords.get("Banco").x,
                ty: this.clusterCoords.get("Banco").y,
                shape: this.clusterLayout.nodes().find((n) => n.name === name)
                  .shape,
                color: node.color,
                stroke: node.color,
                type: TYPE_MORTGAGE,
                r: this.scales.r(
                  this.land.find(
                    (l) => l["Name"] === transaction["Predio_Nom"]
                  )["Surface"]
                ),
              };
              this.mortgagesLayout.nodes([
                ...this.mortgagesLayout.nodes(),
                mortgagedNode,
              ]);

              // mortgagedNode.tx = this.clusterCoords.get("Banco").x;
              // mortgagedNode.ty = this.clusterCoords.get("Banco").y;

              gsap.to(mortgagedNode, {
                // x: this.clusterCoords.get("Banco").x,
                // y: this.clusterCoords.get("Banco").y,
                color: this.clusterCoords.get("Banco").color,
                ease: "power3.out",
                duration: 2,
                overwrite: true,
              });
            }
          } else {
            // one of the mortgages canceled
            if (--node.mortgages === 0)
              this.mortgagesLayout.nodes([
                ...this.mortgagesLayout.nodes().filter((n) => n.name !== name),
              ]);
          }
        } else {
          if (!node) {
            console.log("not found", name);
            return;
          }
          // console.log(node);
          this.landClusterMap.set(name, targetCluster.name);
          changedNodes.push({
            name,
            color: targetCluster.color,
          });

          if (this.renderView === RENDER_VIEW.CLUSTERS) {
            node.tx = targetCluster.x;
            node.ty = targetCluster.y;
          }

          if (animate) {
            gsap.to(node, {
              // x: targetCluster.x,
              // y: targetCluster.y,
              color: targetCluster.color,
              ease: "power3.out",
              duration: 2,
              overwrite: true,
            });
          } else {
            node.color = targetCluster.color;
          }
        }
      }

      if (animate) {
        // sends only changed nodes
        this.onLandClusterChange(changedNodes);
      } else {
        // can't send too many state changes
        this.onLandClusterChange(
          [...this.landClusterMap.entries()].map(([plot, cluster]) => ({
            name: plot,
            color: this.clusterCoords.get(cluster).color,
          }))
        );
      }
    }
  }

  onCanvasClick({ clientX, clientY }) {
    function isIntersect(point, circle) {
      return (
        Math.sqrt((point.x - circle.x) ** 2 + (point.y - circle.y) ** 2) <
        circle.r
      );
    }

    const pos = {
      x: clientX,
      y: clientY,
    };

    let found = false;

    // Get pixel color under hit region
    const pixel = this.hitCtx.getImageData(pos.x, pos.y, 1, 1);
    const clickedColor = `rgb(${pixel.data[0]}, ${pixel.data[1]}, ${pixel.data[2]})`;
    console.log("clicked on", pos.x, pos.y, clickedColor);

    this.clusterLayout.nodes().forEach((node) => {
      if (this.renderView === RENDER_VIEW.CLUSTERS) {
        if (isIntersect(pos, node)) {
          found = true;
          console.log("clicked node", node.name);
          this.onNodeClick(node);
        }
      } else if (this.renderView === RENDER_VIEW.MAP) {
        if (clickedColor === node.hitColor) {
          found = true;
          this.onNodeClick(node);
          console.log("clicked plot", node.name, "on map");
        }
      }
    });
    if (!found) {
      this.onLandClusterChange(
        [...this.landClusterMap.entries()].map(([plot, cluster]) => {
          return {
            name: plot,
            color: this.clusterCoords.get(cluster).color,
          };
        })
      );
      this.onNodeClick();
    }

    if (DEBUG_CANVAS_CLICK) {
      this._debugInteraction.x = pos.x;
      this._debugInteraction.y = pos.y;
    }
  }

  drawMap() {
    // Draw the map
    this.ctx.save();
    this.ctx.translate(
      this.width / 2 - this.projectionSettings.width / 2,
      this.height / 2 - this.projectionSettings.height / 2
    );
    this.serialize().forEach(({ name, color, geospatial }) => {
      this.ctx.fillStyle = name === this._focused ? `white` : color;
      if (geospatial) {
        this.ctx.fill(geospatial);
      }
    });
    this.ctx.restore();

    // Draw the hit region
    this.hitCtx.save();
    this.hitCtx.translate(
      this.width / 2 - this.projectionSettings.width / 2,
      this.height / 2 - this.projectionSettings.height / 2
    );
    this.serialize().forEach(({ hitColor, color, geospatial }) => {
      this.hitCtx.fillStyle = hitColor;
      if (geospatial) {
        this.hitCtx.fill(geospatial);
      }
    });
    this.hitCtx.restore();
  }

  drawNodes() {
    this.ctx.save();
    this.ctx.globalAlpha = 1 - this._mapOpacity.value;
    // Draw the items
    this.serialize().forEach(
      ({ color, strokeStyle, shape, geospatial, map, name, x, y, r }) => {
        this.ctx.fillStyle = name === this._focused ? `white` : color;
        this.ctx.strokeStyle = strokeStyle;
        this.ctx.beginPath();
        this.ctx.arc(x, y, r, 0, 2 * Math.PI);
        this.ctx.fill();
        this.ctx.stroke();
      }
    );
    this.ctx.restore();
  }

  drawLegend() {
    this.ctx.fillStyle = "white";
    this.ctx.font = ".9rem Barlow, 'Helvetica Neue', 'Arial'";
    this.ctx.textAlign = "center";
    this.ctx.textBaseline = "middle";
    for (const [key, { x, y }] of this.clusterCoords) {
      this.ctx.strokeStyle = "black";
      this.ctx.lineWidth = 2;
      this.ctx.strokeText(key, x, y);
      this.ctx.fillText(key, x, y);
    }
    this.ctx.lineWidth = 1;
  }

  drawMapLegend() {
    const totalClusters = this.clusterCoords.size;
    let idx = 0.5;
    this.ctx.font = ".9rem Barlow, 'Helvetica Neue', 'Arial'";
    for (const [key, { color }] of this.clusterCoords) {
      this.ctx.fillStyle = color;
      // this.ctx.strokeStyle = "white";
      this.ctx.beginPath();
      const y =
        this.height * (isMobile ? 0.15 : 0.1) +
        (idx / totalClusters) * (this.height * 0.5);
      const x = isMobile ? 20 : 25;
      this.ctx.arc(x, y, isMobile ? 4 : 8, 0, 2 * Math.PI);
      this.ctx.fill();
      // this.ctx.stroke();
      this.ctx.fillStyle = "white";
      this.ctx.textBaseline = "middle";
      this.ctx.fillText(key, x + 15, y);
      idx++;
    }
  }

  tick() {
    this._tickNum++;
    // console.time("cluster");
    const c = this.clusterLayout;
    const m = this.mortgagesLayout;

    c.force("x").initialize(c.nodes());
    c.force("y").initialize(c.nodes());
    c.tick();
    // console.log(c.nodes());
    // c.alpha(0.4).restart();
    m.tick();
    // m.alpha(0.4).restart();
    // console.timeEnd("cluster");
    return this.clusterLayout;
  }
}

export default LandClusters;
