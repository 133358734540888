import { useCallback, useState } from "react";
import { ScrollIndicator } from "./ScrollIndicator";

export const Intro = ({ onClick = () => {} }) => {
  const [skipPressed, setSkipPressed] = useState();

  const onSkipPressed = useCallback(() => {
    if (!skipPressed) {
      onClick();
      setSkipPressed(true);
    }
  }, []);

  return (
    <div className="full-height flex flex-column items-center justify-center relative">
      <div className="h1 text-center mb2">Uraba Transaction Network</div>

      <div
        onClick={onSkipPressed}
        className="button h4 font-heavy uppercase p1 border border-thicker rounded"
      >
        enter the platform
      </div>

      {skipPressed && (
        <ScrollIndicator
          // text="continue below"
          className="absolute bottom-0 fade-in"
        />
      )}
    </div>
  );
};
