import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  select,
  scaleTime,
  scaleLinear,
  timeFormat as d3TimeFormat,
  sum,
  max,
  timeMonth,
} from "d3";
import { rollups } from "d3-array";

import "./Timeline.css";
import {
  clamp,
  TRANSACTION_FINANCIALIZATION,
  TRANSACTION_TRANSFER,
} from "./utils";
import PlaySVG from "./play.svg";
import PauseSVG from "./pause.svg";

const ICON_WIDTH = 32;

export default Timeline = ({
  className = ``,
  start,
  end,
  date,
  marginX = 50,
  height = 100,
  fontSize = "1em",
  transactions = [],
  isPlaying = false,
  onClick = () => {},
  onPlay = () => {},
  onPause = () => {},
  /**
   * Of the form
   * [{start, end, description}]
   */
  annotations = [],
}) => {
  const [currentDate, setCurrentDate] = useState(date);
  const barChartRef = useRef();
  const timeFormat = d3TimeFormat("%m/%Y");

  const timeScale = useMemo(() => {
    return scaleTime()
      .domain([start, end])
      .range([marginX + ICON_WIDTH, window.innerWidth - marginX]);
  }, [start, end]);

  const percentageScale = useMemo(() => {
    return scaleTime().domain([start, end]).range([0, 1]);
  }, [start, end]);

  useEffect(() => {
    setCurrentDate(date);
  }, [date]);

  const onTimelineClick = useCallback(
    (e) => {
      e.stopPropagation();

      console.log(e);

      const current = timeMonth(timeScale.invert(e.clientX));

      // Don't make 1 because it will give us NaN
      const percentage = clamp(percentageScale(current), 0, 0.99);

      onClick(percentage);
    },
    [onClick, start, end, percentageScale, timeScale]
  );

  useEffect(() => {
    const g = select(barChartRef.current);
    const gPrimaryAxis = g
      .append("g")
      .attr("class", "g-primary-axis")
      .attr("opacity", 1);
    const gSecondaryAxis = g
      .append("g")
      .attr("class", "g-secondary-axis")
      .attr("opacity", 0.5);

    const primaryGroupBy = rollups(
      transactions.filter((t) => t["Trans_Category"] === TRANSACTION_TRANSFER),
      (transactions) => sum(transactions, (d) => d["Predio_Ha"]),
      (d) => d["Tiempo_Month"]
    );
    primaryGroupBy.sort((a, b) => b[1] - a[1]);

    const secondaryGroupBy = rollups(
      transactions.filter(
        (t) => t["Trans_Category"] === TRANSACTION_FINANCIALIZATION
      ),
      (transactions) => sum(transactions, (d) => d["Predio_Ha"]),
      (d) => d["Tiempo_Month"]
    );
    secondaryGroupBy.sort((a, b) => b[1] - a[1]);

    const primaryScale = scaleLinear()
      .domain([0, max(primaryGroupBy, (d) => d[1])])
      .range([5, height / 2]);

    const secondaryScale = scaleLinear()
      .domain([0, max(secondaryGroupBy, (d) => d[1])])
      .range([5, height / 2]);

    gPrimaryAxis
      .selectAll("rect")
      .data(primaryGroupBy)
      .enter()
      .append("rect")
      .attr("x", (d) => timeScale(d[0]))
      .attr("y", (d) => height / 2 - primaryScale(d[1]))
      .attr("width", 2)
      .attr("height", (d) => primaryScale(d[1]))
      .attr("fill", "white");

    gSecondaryAxis
      .selectAll("rect")
      .data(secondaryGroupBy)
      .enter()
      .append("rect")
      .attr("x", (d) => timeScale(d[0]))
      .attr("y", height / 2)
      .attr("width", 2)
      .attr("height", (d) => secondaryScale(d[1]))
      .attr("fill", "white");

    return function cleanup() {
      select(barChartRef.current).innerHTML = "";
    };
  }, [barChartRef, transactions]);

  return (
    <div id="timeline" className={`${className}`}>
      <div
        className="col-12 full-width relative flex flex-row items-center"
        style={{
          width: `100vw`,
          height: `${height}px`,
        }}
      >
        <div
          className="absolute flex flex-row"
          style={{ left: `${marginX - 10}px` }}
        >
          <div
            onClick={() => {
              if (!isPlaying) {
                onPlay();
              } else {
                onPause();
              }
            }}
            className="mr1"
          >
            <img
              className="pointer"
              src={isPlaying ? PauseSVG : PlaySVG}
              height={ICON_WIDTH}
              width={ICON_WIDTH}
              alt={`${isPlaying ? `Pause` : `Play`} Button`}
            />
          </div>
        </div>
        <div
          className="time__tracker absolute left-0"
          style={{
            left: `${timeScale(currentDate)}px`,
            marginTop: `-35px`,

            fontSize: `16px`,
          }}
        >
          <div
            className="center bold absolute left-0 text-center"
            style={{ opacity: `0.8`, marginLeft: `-25px` }}
          >
            {timeFormat(currentDate)}
          </div>
          <div style={{ color: `red` }}>
            <svg height={height} width="2">
              <line y1={20} y2={height * 1} stroke="red" strokeWidth={3} />
            </svg>
          </div>
        </div>
        <svg
          className="double-axis-bar-chart"
          style={{
            boxSizing: "border-box",
          }}
          ref={barChartRef}
          onClick={onTimelineClick}
        >
          <text
            fontSize={fontSize}
            fill="white"
            className="italic"
            alignment-baseline="hanging"
            x={window.innerWidth / 2}
            y={height / 2}
            dy={(-2 * height) / 5}
            textAnchor="middle"
          >
            héctereas transferidas
          </text>
          <text
            fontSize={fontSize}
            alignment-baseline="hanging"
            className="italic"
            fill="white"
            x={window.innerWidth / 2}
            y={height / 2}
            dy={height / 5}
            textAnchor="middle"
          >
            héctereas financializadas
          </text>
          <g ref={barChartRef} />
          <line
            x1={timeScale.range()[0]}
            x2={timeScale.range()[1]}
            y1="50%"
            y2="50%"
            stroke="red"
            strokeWidth={1.5}
          />
        </svg>
      </div>
    </div>
  );
};
