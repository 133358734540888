import { useCallback, useEffect } from "react";

import "./ProgressBar.css";

export const ProgressBar = () => {
  const onScroll = useCallback((e) => {
    // console.log(e, document);
    const scrollPercentage =
      window.scrollY / (document.body.scrollHeight - window.innerHeight);

    document.documentElement.style.setProperty(
      "--progress-tracker-width",
      `${scrollPercentage * 100}vw`
    );
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", onScroll);
    return function cleanup() {
      document.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <div className="progress-bar__container">
      <div className="progress-bar__tracker"></div>
    </div>
  );
};
