export const setFromArrayValues = (data, ...f) => {
  const values = [...data.flatMap((d) => f.flatMap((k) => k(d)))];
  const sortedValues = [...values].sort();
  return new Set(sortedValues);
};

export const smallestScreenDimension = () =>
  window.innerWidth < window.innerHeight
    ? window.innerWidth
    : window.innerHeight;

export const isMobile = window.matchMedia("(any-hover: none)").matches;

export const inactivityTime = (
  inactivityCb = () => {},
  duration = 120 * 1000
) => {
  let time;

  console.log("starting attract loop timer");

  window.onload = resetTimer;
  // DOM Events
  document.onload = resetTimer;
  document.onmousemove = resetTimer;
  document.onmousedown = resetTimer;
  document.ontouchstart = resetTimer;
  document.onclick = resetTimer;
  document.onkeydown = resetTimer;
  document.addEventListener("scroll", resetTimer, true);

  function resetTimer() {
    clearTimeout(time);
    time = setTimeout(inactivityCb, duration);
  }
};

/**
 * This partial function checks whether an event's date
 * falls within a certain time range.
 *
 * @param {[Date, Date]} param1
 * @param {function} dateAccessor function for accessing the date value
 */
export const filterInDateRange =
  ([filterStart, filterEnd], dateAccessor) =>
  (d) => {
    const date = dateAccessor(d).getTime();
    const start = filterStart.getTime();
    const end = filterEnd.getTime();

    return start <= date && date <= end;
  };

export const clamp = (value, min, max) => Math.max(Math.min(value, max), min);

export const CLUSTER_ORDER = [
  "Banco",
  "Otros Grupos",
  "El Retiro",
  "Grupo Restrepo Arango",
  "Bananeras de Urabá",
  "Hasbún",
  "Velaba Morris",
  "Taparto",
  "Villanueva",
  "Juanca Samarkanda",
  "Sara Palma",
];

// indicates whether a transaction
export const transactionIsMortgaged = (t) => t["Cluster_Target"] === `Banco`;

export const colorByCluster = (cluster) => {
  switch (cluster) {
    case `Estado Colombiano`:
      // return `#595959`;
      return `#404040`;
    case `El Retiro`:
    case `Chiquita`:
      return `#002EC3`;
    case `Hasbún`:
      return `#6800D7`;
    case `Bananeras de Urabá`:
      return `#C872FF`;
    case `Grupo Restrepo Arango`:
      return `#00FFFF`;
    case `Sara Palma`:
      return `#FB9A99`;
    case `Velaba Morris`:
      return `#33A02C`;
    case `Juanca Samarkanda`:
      return `#FDBF6F`;
    case `Villanueva`:
      return `#FF7F00`;
    case `Taparto`:
      return `#B2DF8A`;
    case `Banco`:
      return `#FFE92C`;
    case `Unassigned`:
      return `rgba(255, 255, 255, .6)`;
    default:
      return `#D6D6D6`;
  }
};

export const TRANSACTION_FINANCIALIZATION = `Financialization`;
export const TRANSACTION_TRANSFER = `Transfer`;

export const renderGroups = [
  { name: `Banco`, prefix: `banco`, plots: ["Banco"] },
  { name: `California`, prefix: `california`, plots: ["La Niña"] },
  {
    name: `Hasbúns holdings`,
    prefix: `hasbuns`,
    plots: ["Lote A", "Lote B - Rita Maria 2", "Rita María"],
  },
  {
    name: `La Negra Massacre`,
    prefix: `la-negra-massacre`,
    plots: ["La Negra 1"],
  },
  {
    name: `Honduras Massacre`,
    prefix: `honduras-massacre`,
    plots: ["Madrigal"],
  },
  {
    name: `Plots brought by Chiquita`,
    prefix: `chiquita`,
    plots: [
      "Taparto",
      "Las Palmas",
      "La Francina",
      "La 616",
      "Amelia 2",
      "Canoas",
      "Balboa 1",
      "Balboa 3",
      "Eupol 1",
      "Cafetal",
      "Amelia 1",
      "El Bosque",
      "El Mandarino",
      "Algun Dia",
      "Amalfia Eulalia",
      "Julia Sofia",
      "La Garcia",
      "La Fortuna B",
      "La Chácara",
      "La Magdalena",
      "La Huerta",
      "La Linda",
      "La Mora",
      "La Negra 2",
      "La Negra 3",
      "La Negra 4",
      "La Union",
      "Villa Beatriz",
      "Sonsoles A",
      "Santa Rita",
      "Villa Luz",
      "Manzana Villa Nueva",
      "Manzana A",
      "Salpicón",
      "Villa Fabiola",
    ],
  },
  {
    name: `Echeverri Initial`,
    prefix: `echeverri_initial`,
    plots: [
      "La Perla",
      "Amelia 2",
      "La Niña",
      "Santa Bárbara 2",
      "Santa Barbara 1",
      "Santa Barbara 3",
      "Salpicón",
      "La Toyosa A",
      "La Toyosa B",
      "Madrigal",
      "Burbuja de Amor",
      "La Magdalena",
      "La Negra 1",
      "La Negra 2",
      "La Negra 3",
      "La Negra 4",
      "Algo es Algo",
      "La Unión",
    ],
  },
  {
    name: `Paramilitary`,
    prefix: `paramilitary`,
    plots: [
      "Agrospina",
      "Amelia 1",
      "Amelia 2",
      "Ana María",
      "Arcua",
      "Argentina Los Bongos",
      "Bodegas",
      "Buena Vida",
      "Buenos Aires",
      "Cafetal",
      "Claudia Sofia",
      "Costa Rica",
      "El Bosque",
      "El Paraíso",
      "El Porvenir",
      "El Semillero",
      "Guadalupe",
      "Indira",
      "Juanca",
      "Katia",
      "La Cuña",
      "La Esperanza 1",
      "La Esperanza 2",
      "La Magdalena",
      "La Mónica 2",
      "La Tagua",
      "La Toyosa A",
      "La Toyosa B",
      "Los Cativos",
      "Madrigal",
      "Manzana A",
      "Mapana",
      "Providencia",
      "Río Grande",
      "Rita María",
      "Salpicón",
      "Samarkanda A",
      "Samarkanda B",
      "Santa Marta",
      "Taparto",
      "Ucrania",
      "Velaba 2",
      "Veracruz",
      "Villa Alicia 2",
      "Yerbasal",
    ],
  },
  {
    name: `Puerto Declaratoria`,
    prefix: `puerto_declaratoria`,
    plots: [
      "Bodegas",
      "Buena Vida",
      "Canoas",
      "Carretera A",
      "Carretera B",
      "Costa Rica",
      "Guadalupe",
      "Indira",
      "La Cuña",
      "La Esperanza 1",
      "La Fortuna A",
      "La Fortuna C",
      "La Islita",
      "La Liliana Morelo",
      "La Malagueña",
      "La Suerte",
      "Las Camelias 1",
      "Las Camelias 2",
      "Las Margaritas",
      "Las Muchachas 842",
      "Los Cámbulos",
      "Lote de Terreno",
      "Mapana",
      "María Consuelo",
      "Pamplonita A",
      "San Jacinto",
      "Santa Ana",
      "Santa Anita",
      "Sonsoles A",
      "Ucrania",
      "Vallan Viendo 68636",
      "Vallan Viendo 71819",
      "Velaba 2",
    ],
  },
  {
    name: `Puerto Compra`,
    prefix: `puerto_compra`,
    plots: [
      "Bodegas",
      "Río Grande",
      "El Semillero",
      "Claudia Sofia",
      "Julia Sofía",
      "Manzana Villa Nueva",
      "La Sarita",
      "Eupol 1",
      "La Chácara",
      "Bodegas",
      "Río Grande",
      "La Sarita",
      "Julia Sofía",
      "Amalfia Eulalia",
      "Velaba 2",
    ],
  },
  {
    name: `Offshore`,
    prefix: `offshore`,
    plots: [
      "Algo es Algo",
      "Algún Día",
      "Amalfia Eulalia",
      "Amelia 2",
      "Argentina Los Bongos",
      "Cafetal",
      "La 616",
      "La Chácara",
      "La Francina",
      "La Huerta",
      "La Magdalena",
      "La Negra 1",
      "La Negra 2",
      "La Negra 3",
      "La Negra 4",
      "La Unión",
      "Lote A",
      "Lote B - Rita Maria 2",
      "Lote B - Rita Maria 2",
      "Papaya Lote Terreno",
      "Rita María",
      "Santa Rita",
      "Sonsoles A",
      "Sonsoles Uva 1",
      "Taparto",
      "Veracruz",
      "Villa Beatriz",
      "Villa Luz",
    ],
  },
];
