import ReactDOM from "react-dom";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { InView } from "react-intersection-observer";

import { Intro } from "./Intro";
import { Ontology } from "./Ontology";
import Timeline from "./Timeline";
import DataFetcher from "./DataFetcher";
import LandClusters, { RENDER_VIEW } from "./LandClusters";

import plotsJSON from "./plots.json";
import "./style.css";
import { CLUSTER_ORDER, inactivityTime, isMobile } from "./utils";
import { TransactionHistory } from "./TransactionHistory";
import { ProgressBar } from "./ProgressBar";
import { clamp } from "gsap/all";

const DEBUG_TIMELINE = false;
const DURATION_BEFORE_RELOAD = 120 * 1000;

const UI = ({
  transactionsStartMonth,
  transactionsEndMonth,
  transactions,
  monthlyTransactions,
  timeMonth,
  clusterDict,
  land,
  nodeDict,
  cases,
}) => {
  const [touchInteraction, setTouchInteraction] = useState(false);
  const [trackerProps, setTrackerProps] = useState({
    start: transactionsStartMonth,
    end: transactionsEndMonth,
    date: transactionsStartMonth,
    transactions,
  });

  const [vizIsPlaying, setVizIsPlaying] = useState(false);
  const [panelProps, setPanelProps] = useState({});
  const [canvasContainer, setCanvasContainer] = useState();

  const landClusters = useRef();

  const onKeyPress = useCallback((e) => {
    if (e.code === `Space`) {
      e.preventDefault();
      setVizIsPlaying((s) => !s);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", onKeyPress);

    inactivityTime(() => {
      location.reload();
    }, DURATION_BEFORE_RELOAD);

    return function cleanup() {
      window.removeEventListener("keydown", onKeyPress);
    };
  }, []);

  const onChangeView = useCallback((view) => () => {
    setVizIsPlaying(false);
    landClusters.current.changeRenderView(view);
  });

  // We need an initial touch interaction on the page
  // so that the videos will autoplay after
  const onTouchInteraction = useCallback(() => {
    if (!touchInteraction) {
      setTouchInteraction(true);
    }
  }, []);

  const onTimelineClick = useCallback(
    (progress) => {
      landClusters.current.seek(progress);
      setVizIsPlaying(false);
    },
    [landClusters]
  );

  const onTimelinePause = useCallback(() => {
    // landClusters.current.pause();
    setVizIsPlaying(false);
  }, [landClusters]);

  const onTimelinePlay = useCallback(() => {
    // landClusters.current.play();
    setVizIsPlaying(true);
  }, [landClusters]);

  const onNodeClick = useCallback(
    (node) => {
      setPanelProps({ focused: node });
    },
    [landClusters]
  );

  const onCanvasMount = useCallback((r) => {
    setCanvasContainer(r);
  }, []);

  useEffect(() => {
    if (!canvasContainer) return;

    landClusters.current = new LandClusters({
      addForceManyBody: isMobile ? false : true,
      el: canvasContainer,
      edges: monthlyTransactions,
      nodes: nodeDict,
      land,
      clusters: clusterDict,
      clustersOrder: CLUSTER_ORDER,
      cases,
      playOnStart: false,
      plotShapes: plotsJSON,
      squareGrid: isMobile,
      onNodeClick,
      onTimelineChange: ({ date }) => {
        setTrackerProps({
          start: transactionsStartMonth,
          end: transactionsEndMonth,
          date,
          transactions,
        });
      },
      onLandClusterChange: (nodes) => {
        // setChangedNodes(nodes);
      },
      timeline: {
        start: transactionsStartMonth,
        end: transactionsEndMonth,
        duration: 100000,
        data: monthlyTransactions,
        quantizeFunc: timeMonth,
      },
    });
  }, [canvasContainer]);

  useEffect(() => {
    if (vizIsPlaying) {
      landClusters.current?.play();
    } else {
      landClusters.current?.pause();
    }
  }, [vizIsPlaying]);

  const renderTimeline = () => {
    return (
      <div className="full-height relative">
        <div className="absolute top-0 left-0 ml2 mt2 h2 flex flex-row z2">
          <div
            className="mr1 button border p1 rounded h5"
            onClick={onChangeView(RENDER_VIEW.CLUSTERS)}
          >
            View clusters
          </div>
          <div
            className="button border p1 rounded h5"
            onClick={onChangeView(RENDER_VIEW.MAP)}
          >
            View map
          </div>
        </div>
        <div ref={onCanvasMount} className="absolute top-0 left-0" />
        <TransactionHistory
          className="absolute right-0 mr2"
          transactions={trackerProps.transactions}
          {...panelProps}
        />
        <Timeline
          isPlaying={vizIsPlaying}
          className="absolute"
          start={trackerProps.start}
          end={trackerProps.end}
          height={isMobile ? 50 : 100}
          date={trackerProps.date}
          fontSize={isMobile ? "x-small !important" : "12"}
          transactions={trackerProps.transactions}
          marginX={clamp(window.innerWidth / 10, 25, 100)}
          onClick={onTimelineClick}
          onPlay={onTimelinePlay}
          onPause={onTimelinePause}
          // annotations={caseStudies}
        />
      </div>
    );
  };

  return DEBUG_TIMELINE ? (
    renderTimeline()
  ) : (
    <div>
      <ProgressBar />
      <Intro onClick={onTouchInteraction} />

      {touchInteraction && (
        <div className="fade-in">
          <div className="half-height"></div>
          <Ontology />
          <InView
            threshold={0.95}
            onChange={(inView) => {
              !inView && setVizIsPlaying(false);
              inView && setVizIsPlaying(true);
            }}
          >
            {renderTimeline()}
          </InView>
        </div>
      )}
    </div>
  );
};

const DataProvider = ({ children }) => {
  const app = useMemo(() => new DataFetcher(), []);
  const [data, setData] = useState();

  useEffect(() => {
    document.documentElement.style.setProperty(
      `--vh`,
      `${window.innerHeight * 0.01}px`
    );
    window.addEventListener("resize", () => {
      document.documentElement.style.setProperty(
        `--vh`,
        `${window.innerHeight * 0.01}px`
      );
    });
    app.init().then(
      ({
        transactionsStartMonth,
        transactionsEndMonth,
        transactions,
        monthlyTransactions,
        timeMonth,
        clusterDict,
        land,
        nodeDict,
        cases,
        // networkEl,
      }) => {
        setData({
          transactionsStartMonth,
          transactionsEndMonth,
          transactions,
          monthlyTransactions,
          timeMonth,
          clusterDict,
          land,
          nodeDict,
          cases,
        });
      }
    );
  }, []);

  return data ? children(data) : null;
};

ReactDOM.render(
  <DataProvider>{(data) => <UI {...data} />}</DataProvider>,
  document.getElementById("ui")
);
