/**
 * This is a base class that provides callbacks for playing back
 * provided temporal data, at a specified granularity
 */
class PlayableTimeline {
  _intervalID = null;
  // start-end are dates: duration is ms
  constructor({
    start,
    end,
    data,
    duration,
    quantizeFunc,
    onChange = () => {},
  }) {
    this.progress = 0;

    this.data = data;
    this.start = start;
    this.end = end;
    this.duration = duration;
    this.onChange = onChange;

    this.steps = quantizeFunc.every(1).range(start, end);
    this._stepIdx = 0;
    this.current = this.steps[this.stepIdx];

    this._play = this._play.bind(this);
    // this.play();
  }

  /**
   *
   * @param {float} progress a number between [0,1]
   */
  seek(progress) {
    // this.pause();
    const targetIdx = Math.floor(progress * this.steps.length);

    this.stepIdx = -1;
    this.initLayout();
    while (this.stepIdx < targetIdx) {
      this.stepIdx += 1;
      this.toStep(this.stepIdx, false);
    }
    // this.play();
  }

  play() {
    this._intervalID = window.setInterval(
      this._play,
      this.duration / this.steps.length
    );
  }

  _play() {
    const reachedEnd = this.stepIdx + 1 === this.steps.length - 1;

    if (reachedEnd) {
      this.reset();
      this.pause();
    } else {
      this.toStep(this.stepIdx + 1, true);
    }
  }

  initLayout() {
    // This is a bit of hack and is meant to be overridden.
  }

  reset() {
    this.stepIdx = 0;
  }

  pause() {
    window.clearInterval(this._intervalID);
  }

  toStep(newIdx, animateTransactions) {
    let filteredData = this.data.get(this.steps[newIdx]) || [];

    this.stepIdx = newIdx;
    this.onChange({
      data: filteredData,
      date: this.steps[newIdx],
      animateTransactions,
    });
  }

  set stepIdx(idx) {
    this.progress = idx / this.steps.length;
    this._stepIdx = idx;
  }

  get stepIdx() {
    return this._stepIdx;
  }
}

export default PlayableTimeline;
