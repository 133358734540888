import React, { useEffect, useState } from "react";
import { timeFormat } from "d3";

import "./TransactionHistory.css";

import { colorByCluster } from "./utils";

const tf = timeFormat("%d/%m/%Y");

export const TransactionHistory = ({
  transactions,
  focused,
  className = "",
}) => {
  const [focusedTransactions, setFocusedTransactions] = useState([]);

  useEffect(() => {
    // console.log(focused, transactions);
    if (focused?.name) {
      const _ = transactions.filter((t) => t["Predio_Nom"] === focused.name);
      console.log(_);
      _.sort((a, b) => a["Tiempo_Day"] - b["Tiempo_Day"]);
      setFocusedTransactions(_);
    } else {
      setFocusedTransactions([]);
      // no plot focused
    }
  }, [transactions, focused]);
  return focused?.name ? (
    <div
      className={`transaction-history__container fade-in h5 mt2 ${className}`}
    >
      <div className="h3 pb1">{focused.name}</div>
      <div className="transaction-history__list">
        {focusedTransactions.map((t, idx) => (
          <div
            style={{
              backgroundColor:
                idx % 2 === 0 ? `rgb(20, 20, 20)` : `transparent`,
            }}
            className="flex flex-row p1 items-center"
          >
            <div className="flex flex-column mr1 col-4 right-align">
              <div className="">{tf(t["Tiempo_Day"])}</div>
              <div className="h6 italic">{t["Trans_Name"]}</div>
            </div>
            <div
              className="col-4 right-align"
              style={{
                color: colorByCluster(t["Cluster_Source"]),
              }}
            >
              {t["Name_Source"]}
            </div>
            <div className="mx1">➔</div>
            <div
              style={{
                color: colorByCluster(t["Cluster_Target"]),
              }}
              className="col-4"
            >
              {t["Name_Target"]}
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : null;
};
