import { csv } from "d3-fetch";
import { timeParse } from "d3-time-format";
import { timeMonth, timeDay } from "d3-time";
import { group, extent, rollup, min, max } from "d3-array";

import {
  setFromArrayValues,
  TRANSACTION_FINANCIALIZATION,
  TRANSACTION_TRANSFER,
} from "./utils";

const parseDate = timeParse("%d/%m/%Y");
const parseCurrency = (d) => +d.replace(/,/g, "").replace(/\$/g, "");

const START_YEAR = 1950;
const END_YEAR = 2021;

const transactionCategory = (t) => {
  switch (t) {
    case `Adjudicación`:
    case `Transferencia`:
      return TRANSACTION_TRANSFER;
    case `Financialización`:
    case `Hipoteca`:
      return TRANSACTION_FINANCIALIZATION;
  }
};

const parsers = {
  edges: (d) => ({
    Cluster_Source: d["Cluster_Source"],
    Cluster_Target: d["Cluster_Target"],
    Name_Source: d["Name_Source"],
    Name_Target: d["Name_Target"],
    Predio_Ha: +d["Predio_Ha"],
    Predio_Nom: d["Predio_Nom"],
    Tiempo_Fecha: parseDate(d["Tiempo_Fecha"]),
    Tiempo_Day: timeDay(parseDate(d["Tiempo_Fecha"])), // TODO Make month
    Tiempo_Month: timeMonth(parseDate(d["Tiempo_Fecha"])), // TODO Make month
    Tiempo_Year: +d["Tiempo_Year"],
    Trans_Category: transactionCategory(d["Trans_Category"]),
    Valor_Acto: parseCurrency(d["Valor_Acto"]),
    Valor_2021: parseCurrency(d["Valor_2021"]),
    Valor_2021Ha: parseCurrency(d["Valor_2021Ha"]),
  }),
};

export default class DataFetcher {
  _clusteredNodes = null;
  constructor() {}

  async init() {
    return Promise.all([
      csv("./data/edge_list_15-11.csv", (d) => ({
        // csv("./data/edge_list.csv", (d) => ({
        ...d,
        ...parsers.edges(d),
      })),
      csv("./data/land_15-11.csv", (d) => ({
        ...d,
        Name: d["Predio_Nom"],
        Surface: +d["Superficie (ha.)"],
        Vereda: d["Vereda"],
        Paramilitary: !!d["Paramilitary?"],
        ShapefileExists: d["SHP"] === "TRUE",
        Description: d["Linderos"],
        Latitude: +d["Latitude"],
        Longitude: +d["Longitude"],
      })),
      csv("./data/restrepo_arango_disagg.csv", (d) => ({
        ...d,
        ...parsers.edges(d),
      })),
    ]).then(([transactions, land, ...cases]) => {
      transactions = transactions
        .filter(
          (t) => t["Tiempo_Year"] >= START_YEAR && t["Tiempo_Year"] <= END_YEAR
        )
        .filter(
          (t) =>
            [TRANSACTION_FINANCIALIZATION, TRANSACTION_TRANSFER].includes(
              t["Trans_Category"]
            )

          // t["Trans_Category"] === "Adjudicación" ||
          // t["Trans_Category"] === "Transferencia"
        );
      // .filter(
      //   (t) =>
      //     t["Trans_Category"] === "Financialización" ||
      //     // t["Trans_Category"] === "Embargo" ||
      //     t["Trans_Category"] === "Hipoteca"
      // );
      const monthlyTransactions = group(transactions, (d) => d["Tiempo_Month"]);

      timeMonth
        .every(1)
        // arbitrary dates
        .range(new Date(START_YEAR, 0, 1), new Date(END_YEAR, 12, 1))
        .forEach((month) => {
          // Add missing months
          if (!monthlyTransactions.get(month)) {
            monthlyTransactions.set(month, []);
          }
        });

      const targets = setFromArrayValues(
        transactions,
        (d) => d["Name_Source"],
        (d) => d["Name_Target"]
      );

      // This creates a map of node -> cluster
      const [nodeDict, clusterDict] = [
        ...setFromArrayValues(
          transactions,
          (d) => `${d["Name_Source"]}^^^${d["Cluster_Source"]}`,
          (d) => `${d["Name_Target"]}^^^${d["Cluster_Target"]}`
        ),
      ].reduce(
        ([nodeDict, clusterDict], curr) => {
          const pair = curr.split("^^^");
          const node = pair[0];
          const cluster = pair[1];
          nodeDict.set(node, cluster);
          clusterDict.set(cluster, [...(clusterDict.get(cluster) || []), node]);
          return [nodeDict, clusterDict];
        },
        [new Map(), new Map()]
      );

      const [transactionsStartMonth, transactionsEndMonth] = extent(
        Array.from(monthlyTransactions.keys())
      );

      return {
        transactionsStartMonth,
        transactionsEndMonth,
        monthlyTransactions,
        transactions,
        timeMonth,
        clusterDict,
        land,
        nodeDict,
        cases: [
          {
            name: "Restrepo Arango",
            transactions: cases[0],
            start: min(cases[0], (d) => d["Tiempo_Fecha"]),
            end: max(cases[0], (d) => d["Tiempo_Fecha"]),
          },
        ], // anything here populates the case studies UI
      };
    });
  }
}
