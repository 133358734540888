import { useState, useCallback, useRef, useEffect } from "react";
import { InView } from "react-intersection-observer";

import "./Ontology.css";

const TEXTS = [
  "El análisis de redes expone el proceso histórico de tenencia y concentración de la propiedad de la tierra, estableciendo grupos de actores. Estos grupos se conforman de acuerdo a sus relaciones estratégicas, familiares y al número de las alianzas y movimientos empresariales.",
  "Cada uno de los grupos se compone de decenas de actores y transacciones. Para facilitar la explicación, consolidamos la información en doce grupos.",
  "Esta plataforma interactiva permite ver el proceso de acaparamiento de tierras. Cada círculo corresponde a un predio en Nueva Colonia y su historial de transacciones. El tamaño de cada círculo corresponde al área del predio. Cuando un grupo adquiere la propiedad de un predio, el círculo cambia a la posición y color del grupo comprador. También es posible ver esta información de manera cartográfica.",
];

const VIDEO_URLS = [
  "https://fa-public-assets.fra1.cdn.digitaloceanspaces.com/Uraba/network-analysis-1-1080.mp4",
  "https://fa-public-assets.fra1.cdn.digitaloceanspaces.com/Uraba/network-analysis-2-1080.mp4",
];

// const TriggerVideoOnView = ({ src = ``, children }) => {
//   const vidRef = useRef();

//   return (
//     <div className="ontology-explainer__video-container flex flex-column justify-around">
//       <InView
//         threshold={0.5}
//         style={{
//           position: `sticky`,
//           top: `50%`,
//           left: `50%`,
//           transform: `translate(0%, -50%)`,
//         }}
//         onChange={(inView) => {
//           if (!inView && vidRef.current) {
//             vidRef.current.pause();
//           } else if (inView && vidRef.current) {
//             vidRef.current.currentTime = 0;
//             vidRef.current.play();
//           }
//         }}
//       >
//         <video
//           playsInline
//           ref={vidRef}
//           width={window.innerWidth}
//           height="auto"
//           // controls

//           src={src}
//           preload="auto"
//         />
//       </InView>
//       {children}
//     </div>
//   );
// };

const IntroVideo = ({ src = ``, children }) => {
  const [foregroundVideo, setForegroundVideo] = useState(0);
  const vidRef = useRef();
  const vidRef2 = useRef();

  useEffect(() => {
    if (foregroundVideo === 0) {
      vidRef.current.style.zIndex = "10";
      vidRef2.current.style.zIndex = "-10";
      // vidRef.current.play();
      vidRef.current.currentTime = 0;
      vidRef2.current.currentTime = 0;
      vidRef2.current.pause();
    } else {
      vidRef2.current.style.zIndex = "10";
      vidRef.current.style.zIndex = "-10";
      // vidRef2.current.play();
      vidRef.current.currentTime = 0;
      vidRef2.current.currentTime = 0;
      vidRef.current.pause();
    }
  }, [foregroundVideo]);

  return (
    <div className="ontology-explainer__video-container flex flex-column">
      <div
        // threshold={0.5}
        style={{
          position: `sticky`,
          top: `50%`,
          left: `50%`,
          transform: `translate(0%, -50%)`,
        }}
      >
        <video
          playsInline
          ref={vidRef2}
          className="absolute top-0 left-0"
          width={window.innerWidth}
          height="auto"
          src={VIDEO_URLS[1]}
          preload="auto"
        />
        <video
          playsInline
          ref={vidRef}
          className="top-0 left-0"
          width={window.innerWidth}
          height="auto"
          src={VIDEO_URLS[0]}
          preload="auto"
        />
      </div>
      <InView
        threshold={0}
        onChange={(inView) => {
          if (inView) {
            setForegroundVideo(0);
            vidRef.current.play();
          } else {
            // vidRef2.current.currentTime = 0;
          }
        }}
      >
        <OntologyText text={TEXTS[0]} />
      </InView>
      <InView
        onChange={(inView) => {
          if (inView) {
            setForegroundVideo(1);
            vidRef2.current.play();
          } else {
            // vidRef.current.currentTime = 0;
          }
        }}
      >
        <OntologyText text={TEXTS[1]} />
      </InView>
      <OntologyText text={TEXTS[2]} />
    </div>
  );
};

const OntologyText = ({ text }) => {
  return (
    <div className="ontology-explainer__text-container flex flex-column justify-around items-center">
      <div className="ontology-explainer__text">{text}</div>
    </div>
  );
};

export const Ontology = () => {
  return (
    <div className="ontology-explainer__container">
      {/* <TriggerVideoOnView src={VIDEO_URLS[0]}>
        <OntologyText text={TEXTS[0]} />
      </TriggerVideoOnView>
      <TriggerVideoOnView src={VIDEO_URLS[1]}>
        <OntologyText text={TEXTS[1]} />
      </TriggerVideoOnView>
      <OntologyText text={TEXTS[2]} /> */}

      <IntroVideo />
      <div className="half-height" />
    </div>
  );
};
