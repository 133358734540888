import "./ScrollIndicator.css";
import ChevronSVG from "./chevron.svg";

export const ScrollIndicator = ({ className = "", text = "", style = {} }) => {
  return (
    <div className={`scroll-indicator ${className}`} style={{ ...style }}>
      {text.length > 0 ? (
        <div className="scroll-indicator__text block-font">{text}</div>
      ) : null}
      <img
        className="scroll-indicator__icon pointer"
        src={ChevronSVG}
        height="32"
        width="32"
        alt="Scroll Indicator"
      />
    </div>
  );
};
